import {
  AsyncBoundary,
  TextButton,
  useBottomSheet,
  validateUploadingVideos,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { IconCloseRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import isNil from 'lodash-es/isNil';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { GROUP_URL } from '@/api/base/post';
import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { getFeedListQueryKey } from '@/api/hooks/useGetFeedList';
import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { usePostArticle } from '@/api/hooks/usePostArticle';
import { queryClient } from '@/api/instance';
import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useOpenDialogValidateFormPhotoContest } from '@/hooks/useOpenDialogValidateFormPhotoContest';
import { useStorage } from '@/hooks/useStorage';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupRunningGrowth } from '@/utils/refetch/runningGrowth';
import { postFormSchema } from '@/utils/validate/formSchema/post';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

import useShowPhotoContestInOngoingExerciseGroup from '../../PhotoContest/hooks/useShowPhotoContestInOngoingExerciseGroup';
import { PollFormActivityParamsJSON } from '../../Poll/pages';
import { GroupPostFormAppBar } from '../components/AppBar';
import { GroupPostForm, GroupPostFormHandlerProps } from '../components/GroupPostForm';
import { GroupPostNewStartBottomSheet } from '../components/GroupPostNewStartBottomSheet';
import { typeSafeBoolean } from '../utils';
import * as s from './PostForm.css';

export type GroupPostNewPageParams = Pick<
  PageParams,
  'groupId' | 'postType' | 'content' | 'meetupId' | 'initialPublishPost'
>;

const GroupPostNewPage: ActivityComponentType = () => {
  const { groupId = '' } = usePathParams();
  const {
    content = '',
    postType: queryParamsPostType = 'post',
    meetupId: queryParamsMeetupId,
    initialPublishPost,
    pollOptionValueJSONString,
    from,
    calloutType,
  } = useQueryParams();
  const { group } = useGetGroupDetail(groupId);
  const { bridge } = useBridge();
  const initialPollOption = pollOptionValueJSONString
    ? (JSON.parse(pollOptionValueJSONString) as PollFormActivityParamsJSON)
    : undefined;

  const { data: myInfo } = useGetGroupMe(groupId);

  const { pop, replace, push } = useConnectedActions();
  const backPop = useBack();
  const { data: boardCateogries } = useGetBoardTypeList(Number(groupId));

  const [isPostFormIsGroupOnly, setIsPostFormIsGroupOnly] = useStorage(
    'postFormIsGroupOnly',
    false
  );

  const mentionSuggestionsRef = useRef<HTMLDivElement>(null);

  const pollOptionTitles = () => {
    if (!initialPollOption) return undefined;

    const options = initialPollOption.pollOptionTitles.filter(
      (pollOption) => !isNil(pollOption)
    ) as string[];

    return options.map((option) => ({
      title: option,
    }));
  };

  const isGroupPhotoContestFunnel = useMemo(() => from === 'groupPhotoContest', [from]);
  const formHandler = useForm({
    defaultValues: {
      isGroupOnly: isPostFormIsGroupOnly ?? false,
      content: decodeURIComponent(content),
      images: [],
      originImages: [],
      prevImages: [],
      isPublished: initialPublishPost ? true : false,
      isNoticed: false,
      mentionedUserIds: [],
      poiStreams: [],
      postType: {
        type: queryParamsPostType,
        meetupId: queryParamsMeetupId ? Number(queryParamsMeetupId) : 0,
      },
      isPhotoContestEntry: isGroupPhotoContestFunnel,
      boardCategoryIds: [
        boardCateogries?.boardCategories?.find((item) => item.type === 'default')?.id,
      ],
      poll: initialPollOption
        ? {
            options: pollOptionTitles(),
            title: initialPollOption.title,
            isMultiple: initialPollOption.isMultiple,
          }
        : undefined,
      uploadVideos: [],
    } as GroupPostFormHandlerProps,
  });
  const { watch, getValues, setValue } = formHandler;

  const { open: openBottomSheet } = useBottomSheet();

  const handleGroupPostBottomSheet = () => {
    openBottomSheet({
      element: (
        <GroupPostNewStartBottomSheet
          groupId={groupId}
          formHandler={formHandler}
          hasMention={false}
          replacePage={replace}
          popPage={pop}
          pushPage={push}
        />
      ),
      config: {
        dimOpacity: 0,
      },
    });
  };

  const { setOpenWebviewModal } = useStore();

  useEffect(() => {
    if (queryParamsPostType === 'meetupReview') {
      return;
    }

    if (from === 'groupPhotoContest') {
      setValue('isGroupOnly', false);
      return;
    }

    if (from === 'HeroImage') {
      return;
    }

    if (
      group?.isBoardManaged &&
      boardCateogries?.boardCategories &&
      boardCateogries?.boardCategories.length > 1
    ) {
      setOpenWebviewModal(MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET, true);
      return;
    }

    handleGroupPostBottomSheet();
  }, []);

  useEnterTrackEvent({
    event: 'enter_group_write_post',
    params: {
      groupId,
      queryParamsPostType,
      calloutType,
      from,
    },
    sample: true,
    loggerType: ['APPSFLYER', 'AMPLITUDE', 'KARROT'],
  });

  const handleErrorWithToast = useHandleErrorWithToast();
  const {
    mutate: postArticle,
    isLoading,
    status,
  } = usePostArticle({
    onError: handleErrorWithToast,
    onSuccess: (data) => {
      refetchGroupDetail({ groupId });
      queryClient.refetchQueries([`${GROUP_URL}/${groupId}/notices`]);

      // 채팅방에서 일정 생성시 창 닫기
      if (from === 'chat') {
        bridge.closeRouter({});
        return;
      }

      if (data.post.groupInfo?.isBoardManaged) {
        queryClient.refetchQueries([
          getFeedListQueryKey(groupId ?? ''),
          [data.post.boardCategories[0].id],
        ]);
      }

      queryClient.refetchQueries({
        queryKey: [`${GROUP_URL}/${groupId}/notices`],
      });

      refetchGroupRunningGrowth({ groupId: Number(groupId) });

      replace(
        'GroupPostDetailPage',
        {
          groupId,
          postId: data.post.id,
        },
        {
          animate: true,
        }
      );
    },
  });

  // TODO: 모임사진전
  const shownPhotoContest = useShowPhotoContestInOngoingExerciseGroup({
    groupId,
  });
  const handlePostSubmit = () => {
    const watchData = watch();

    const currentIsPhotoContestEntry = shownPhotoContest
      ? { isPhotoContestEntry: typeSafeBoolean(watchData?.isPhotoContestEntry) }
      : {};

    const data = {
      ...watchData,
      ...currentIsPhotoContestEntry,
      isNoticed: typeSafeBoolean(watchData?.isNoticed),
      isGroupOnly: typeSafeBoolean(watchData?.isGroupOnly),
      isPublished: typeSafeBoolean(watchData?.isPublished),
    };

    // todo: ts-ignore 제거 (참고: https://github.com/daangn/community-web-group/pull/698#issue-1641248802)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const images = (data.images ?? []).map(({ id }) => id);

    const mediasVideo = (data.uploadVideos ?? [])
      .filter(({ uploadedVideoInfo }) => uploadedVideoInfo?.id)
      .map(({ uploadedVideoInfo }) => {
        return {
          id: uploadedVideoInfo?.id,
          type: 'video',
        };
      });

    const mediasImage = images
      .filter((id) => !id.includes('temp') && !id.includes('error'))
      .map((id) => {
        return {
          id,
          type: 'image',
        };
      });

    const invalidUploadVideo = validateUploadingVideos(data.uploadVideos ?? []);
    if (invalidUploadVideo) {
      bridge.openToast({
        toast: {
          body: invalidUploadVideo.message,
        },
      });
      return false;
    }

    if (
      !validateSchemaWithHandleToast(postFormSchema, {
        content: data.content,
        images,
        medias: [...mediasVideo, ...mediasImage],
        postType: data.postType,
      })
    ) {
      return false;
    }

    // TODO: 전국러닝그로스 미션 끝나고 제거 가능
    if (calloutType === 'running-growth-mission2-set-notice') {
      if (data.isNoticed === false) {
        console.log('MISSION 2: 미션을 완료하려면, 게시글을 공지로 등록해주세요.');
        bridge.openToast({
          toast: {
            body: '미션을 완료하려면, 게시글을 공지로 등록해주세요.',
          },
        });
        return;
      }
    }
    if (calloutType === 'running-growth-mission3-set-running-course') {
      if (images.length < 2) {
        console.log(
          'MISSION 3: 미션을 완료하려면, 게시글에 러닝코스를 표시한 사진을 2장 이상 첨부해주세요.'
        );
        bridge.openToast({
          toast: {
            body: '미션을 완료하려면, 게시글에 러닝코스를 표시한 사진을 2장 이상 첨부해주세요.',
          },
        });
        return;
      }
    }

    postArticle(
      {
        groupId,
        ...data,
        poiItems: [...(data.poiStreams?.map(({ poiId, type }) => ({ id: poiId, type })) ?? [])],
        images,
        medias: [...mediasVideo, ...mediasImage],
      },
      {
        onSuccess: () => {
          const isSelectedDefaultBoardCategory =
            boardCateogries?.boardCategories?.find((item) => item.id === data.boardCategoryIds?.[0])
              ?.type === 'default';

          setIsPostFormIsGroupOnly(data.isGroupOnly);

          refetchGroupRunningGrowth({ groupId: Number(groupId) });

          trackEvent({
            event: 'click_write_post',
            params: {
              groupId,
              isSelectedDefaultBoardCategory,
              isNoticed: data.isNoticed,
              isGroupOnly: data.isGroupOnly,
              isPublished: data.isPublished,
              isMentionedUser: (data.mentionedUserIds ?? []).length > 0,
              hasImages: (data.images ?? []).length > 0,
              hasPois: (data.poiStreams ?? []).length > 0,
              hasVideos: (data.uploadVideos ?? []).length > 0,
              videoCount: (data.uploadVideos ?? []).length,
              postType: data.postType?.type,
              hasPoll: !!data.poll,
              pollName: data.poll?.title,
              pollOptionCount: data.poll?.options.length,
              pollMultipleOption: data.poll?.isMultiple,
              role: myInfo?.currentUser.role,
              referrer: from,
              calloutType,
              from,
              photoEvent: data.isPhotoContestEntry,
            },
            sample: true,
            loggerType: extendAppsflyerLoggerType,
          });
        },
      }
    );
  };

  const openDialogValidateFormPhotoContest = useOpenDialogValidateFormPhotoContest();
  const handleSubmitClick = () => {
    if (isLoading || status === 'success') return;

    const isPhotoContestEntry = getValues('isPhotoContestEntry');
    const hasImages = (getValues('images') ?? []).length > 0;

    if (shownPhotoContest && isPhotoContestEntry && !hasImages) {
      return openDialogValidateFormPhotoContest({
        onPrimaryAction: () => {
          setValue('isPhotoContestEntry', false);

          setTimeout(() => {
            handlePostSubmit();
          }, 100);
        },
      });
    } else {
      handlePostSubmit();
    }
  };

  const isValidSubmit = () => {
    const watchData = watch();

    if (watchData.content.length <= 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (watch('originImages').length > 0) {
        return false;
      }
      if (watch('uploadVideos').length > 0) {
        return false;
      }
      return true;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (watch('originImages').some(({ id }) => id.includes('temp') || id.includes('error'))) {
      return true;
    }

    if (watch('uploadVideos').some(({ status }) => status === 'failed' || status === 'uploading')) {
      return true;
    }

    return false;
  };

  const textareaParentRef = useRef<HTMLDivElement>(null);

  const handleFocusTextarea = (cursorEnd = true) => {
    setTimeout(() => {
      const textarea: HTMLInputElement | null | undefined =
        textareaParentRef?.current?.querySelector('[data-name="MentionsInput"]');

      const end = textarea?.textContent?.length;

      if (cursorEnd) {
        textarea?.setSelectionRange(end as number | null, end as number | null);
      }

      textarea?.focus();
    });
  };
  const handleBlurTextarea = () => {
    const textarea: HTMLInputElement | null | undefined = textareaParentRef?.current?.querySelector(
      '[data-name="MentionsInput"]'
    );
    textarea?.blur();
  };

  return (
    <AppScreen
      appBar={{
        title: group?.name,

        backButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={backPop}>
              <IconCloseRegular />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.CloseButtonWrapper} onClick={backPop}>
              <IconCloseRegular />
            </div>
          ),
        },
        renderRight: () => (
          <>
            <TextButton
              onClick={handleSubmitClick}
              variant="secondary"
              isDisabled={isValidSubmit() || isLoading || status === 'success'}
              UNSAFE_style={{ padding: '0.5rem' }}
            >
              완료
            </TextButton>
          </>
        ),
      }}
      accessoryBar={
        <GroupPostFormAppBar
          mode="new"
          handleBlurTextarea={handleBlurTextarea}
          handleFocusTextarea={handleFocusTextarea}
          formHandler={formHandler}
          handleAddButton={handleGroupPostBottomSheet}
          mentionSuggestionsRef={mentionSuggestionsRef}
        />
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupPostForm
          mode="new"
          formHandler={formHandler}
          handleAddButton={handleGroupPostBottomSheet}
          textareaParentRef={textareaParentRef}
          mentionSuggestionsRef={mentionSuggestionsRef}
        />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupPostNewPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
