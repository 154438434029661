import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Divider } from '@community-group/components';

import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { isSuperHost } from '@/utils/role';

import RunningGrowthMissionBanner from '../../../Detail/components/RunningGrowth/RunningGrowthMissionBanner';
import GroupDetailOnboardingBannerForMember from './GroupDetailOnboardingBannerForMember';
import {
  GroupDetailOnboardingBannerForSuperHost,
  isBeforeGroupDescriptionWriteBannerExposureStartDate,
} from './GroupDetailOnboardingBannerForSuperHost';

const GroupDetailOnboardingBanner = ({
  currentUser,
  group,
}: {
  currentUser: GroupCurrentUser;
  group?: GroupDetailPresentation;
}) => {
  const groupId = String(group?.id);
  const { runningGrowthData } = useGetRunningGrowthMission(groupId);

  // * 일반 멤버인 경우
  if (!isSuperHost(currentUser.role)) {
    // ** 2주 이내 가입한 경우에만 온보딩 섹션 & 바텀시트 노출
    const isJoinedIn2Weeks =
      new Date(currentUser.joinedAt) > new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14);
    if (isJoinedIn2Weeks) return <GroupDetailOnboardingBannerForMember groupId={groupId} />;
    return <Divider padding={0} />;
  }

  // * 모임장인 경우 +

  // ** 러닝 그로스 데이터가 있는 경우
  if (isSuperHost(currentUser.role) && runningGrowthData && runningGrowthData.length > 0)
    return (
      <>
        <Divider padding={0} />
        <RunningGrowthMissionBanner groupId={groupId} />
      </>
    );

  // ** 특정 일자 이후에 만들어진 모임만 온보딩 노출
  const groupCreationDate = group?.createdAt ?? '';
  if (isBeforeGroupDescriptionWriteBannerExposureStartDate(groupCreationDate))
    return (
      <>
        <Divider padding={0} />
        <GroupDetailOnboardingBannerForSuperHost groupId={groupId} />
      </>
    );

  return <Divider padding={0} />;
};

export default GroupDetailOnboardingBanner;
