import { Callout, CalloutDescription, Spacing, Typography } from '@community-group/components';
import clsx from 'clsx';

import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';
import { openRunningGrowthGuideBook } from '@/utils/link';

import * as s from './Callout.css';

export type PostFormCalloutType = ActivityQueryParams['calloutType'];

const getCalloutProps = (calloutType?: PostFormCalloutType) => {
  if (calloutType === 'self-introduction') {
    return {
      titleLabel: 'TIP',
      title: '아래 내용으로 모임장님을 소개하는 글을 작성해보세요.',
      calloutContents: [
        '거주지역, 나이, 성별, 취미 등을 이웃들에게 소개해보세요.',
        '모임을 개설한 이유를 작성해보세요.',
      ],
    };
  }

  if (calloutType === 'first-post-mission') {
    return {
      titleLabel: 'TIP',
      title: '아래 질문에 대한 답변을 적어보세요.',
      calloutContents: [
        '어떻게 모임을 만들게 되었나요?',
        '첫 모임은 어떻게 진행하나요?',
        '새로 오는 이웃들이 어떤 글을 작성하면 좋을까요?',
      ],
    };
  }

  if (calloutType === 'member-self-introduction') {
    return {
      titleLabel: 'TIP',
      title: '아래 질문에 대한 답변을 적어보세요.',
      calloutContents: [
        '모임에 어떻게 가입하게 되었나요?',
        '어디에 살고 있고 지금 동네에 살게된지 얼마나 되었나요?',
        '취미나 관심사가 무엇인가요?',
      ],
    };
  }

  if (calloutType === 'member-profile-set-mission') {
    return {
      titleLabel: 'TIP',
      title: '사진을 올리고 자기소개를 작성해야 미션이 완료돼요!',
      calloutContents: [
        '나를 대표하는 사진을 등록해보세요.',
        '거주지역, 나이대, 성별, 취미 등을 이웃들에게 소개해보세요.',
      ],
    };
  }

  if (calloutType === 'popular-group-description') {
    return {
      titleLabel: 'TIP',
      title: '인기있는 모임의 소개를 참고해보세요.',
    };
  }

  if (calloutType === 'running-growth-mission1-set-group-description') {
    return {
      titleLabel: 'TIP',
      title: (
        <Typography typography="bodyL2Regular" color="blue900">
          모임 소개를 200자 이상 입력해주세요.{' '}
          <span
            onClick={() => openRunningGrowthGuideBook(1)}
            style={{
              textDecoration: 'underline',
            }}
          >
            {'미션 가이드 >'}
          </span>
        </Typography>
      ),
    };
  }

  if (calloutType == 'running-growth-mission2-set-notice') {
    const handleRunningGrowthMission2SetNotice = () => {
      openRunningGrowthGuideBook(2);
    };
    return {
      titleLabel: 'TIP',
      title: (
        <Typography typography="bodyL2Regular" color="blue900">
          미션을 완료하려면, 게시글을 공지로 등록해주세요.{' '}
          <span
            onClick={handleRunningGrowthMission2SetNotice}
            style={{
              textDecoration: 'underline',
            }}
          >
            {'미션 가이드 >'}
          </span>
        </Typography>
      ),
      className: s.RunningGrowthInLineCalloutWrapper,
    };
  }

  if (calloutType == 'running-growth-mission3-set-running-course') {
    const handleRunningGrowthMission2SetNotice = () => {
      openRunningGrowthGuideBook(3);
    };
    return {
      titleLabel: 'TIP',
      title: (
        <Typography typography="bodyL2Regular" color="blue900">
          사진을 2장 이상 첨부해서 작성해주세요.{' '}
          <span
            onClick={handleRunningGrowthMission2SetNotice}
            style={{
              textDecoration: 'underline',
            }}
          >
            {'미션 가이드 >'}
          </span>
        </Typography>
      ),
      className: s.RunningGrowthInLineCalloutWrapper,
    };
  }

  if (calloutType == 'running-growth-mission4-set-first-meetup') {
    return {
      titleLabel: 'TIP',
      title: (
        <Typography typography="bodyL2Regular" color="blue900">
          미션 가이드에서 달성 조건과 리워드를 확인해보세요.{' '}
          <span
            onClick={() => openRunningGrowthGuideBook(4)}
            style={{
              textDecoration: 'underline',
            }}
          >
            {'미션 가이드 >'}
          </span>
        </Typography>
      ),
    };
  }

  if (calloutType == 'running-growth-mission5-set-3-meetup') {
    return {
      titleLabel: 'TIP',
      title: (
        <Typography typography="bodyL2Regular" color="blue900">
          미션 가이드에서 달성 조건과 리워드를 확인해보세요.{' '}
          <span
            onClick={() => openRunningGrowthGuideBook(5)}
            style={{
              textDecoration: 'underline',
            }}
          >
            {'미션 가이드 >'}
          </span>
        </Typography>
      ),
    };
  }

  return undefined;
};

type Props = {
  calloutType: PostFormCalloutType;
};

const FormGuideCallout = ({ calloutType }: Props) => {
  const data = getCalloutProps(calloutType);
  if (!data) return null;

  const { className, titleLabel, title, calloutContents } = data;

  return (
    <div className={clsx(s.wrapper, className)}>
      <div className={s.tipLabel}>
        <div className={s.tipBanner}>{titleLabel}</div>
        <label>{title}</label>
      </div>
      <Spacing height={12} />
      {calloutContents && (
        <Callout width="100%">
          <CalloutDescription>
            <ul>
              {calloutContents.map((content) => (
                <li className={s.calloutContentsItem} key={content}>
                  {content}
                </li>
              ))}
            </ul>
          </CalloutDescription>
        </Callout>
      )}
    </div>
  );
};

export default FormGuideCallout;
