import { useXproperty } from './useXproperty';

// !프로덕션에 정의했는지 확인하기
// 프로덕션 : https://xproperty.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR
// 알파 : https://xproperty.alpha.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR

// 사용중인 피쳐플래그 타입
export type FeatureFlagKeysType = {
  shownPhotoContest?: boolean;
  useNotMemberView?: boolean;
  useContestResult?: boolean; // 사진전 종료 결과 섹션 사용 여부
  photoContestVersion?: number;
  useMeetupComment?: boolean; // 일정 댓글/답글 사용 여부
  useMeetupCommentReport?: boolean; // 일정 댓글/답글 신고 사용 여부
  useVideoUpload?: boolean; // 비디오 업로드 사용 여부
  useCommentCountInUserActivity?: boolean; // 유저 활동 내역에 댓글 수 표시 여부
  huggiesGroupJoinMarketingPromotion?: boolean; // 허기스 그룹 가입 마케팅 프로모션

  blockRunningGrowthMission5?: boolean; // [알피] 러닝그로스 5단계 미션 날짜 차단 여부
};

type FeatureFlagType = keyof FeatureFlagKeysType;

export const useFeatureFlag = <T extends FeatureFlagType>(
  featureFlagName: T
): FeatureFlagKeysType[T] | false => {
  const xProperty = useXproperty();

  return xProperty?.communityGroupWebFeatureFlags?.[featureFlagName] ?? false;
};
