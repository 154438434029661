import { useMemo } from 'react';

import FormGuideCallout from '@/components/common/FormGuideCallout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

const RunningGrowthCallout = () => {
  const { calloutType } = useQueryParams();

  const fromRunningGrowthMission = useMemo(() => {
    if (calloutType === 'running-growth-mission1-set-group-description') {
      return true;
    }
    if (calloutType === 'running-growth-mission4-set-first-meetup') {
      return true;
    }
    if (calloutType === 'running-growth-mission5-set-3-meetup') {
      return true;
    }
    return false;
  }, [calloutType]);

  return <>{fromRunningGrowthMission && <FormGuideCallout calloutType={calloutType} />}</>;
};

export default RunningGrowthCallout;
