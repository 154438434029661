import { GroupRunningGrowthResponseGrowthList } from '@community-group/api/lib/group/models';

import RunningGrowthOnboardingBanner from '@/components/common/Banner/RunningGrowthBanner/RunningGrowthOnboardingBanner';

import useRunningGrowthMission from './hooks/useRunningGrowthMission';
export const isRunningGrowthGroup = (data: GroupRunningGrowthResponseGrowthList[] | undefined) => {
  return data?.every((step) => step.isStepCompleted);
};

type Props = {
  groupId: string;
};

const RunningGrowthMissionBanner = ({ groupId }: Props) => {
  const questList = useRunningGrowthMission();

  return (
    <RunningGrowthOnboardingBanner
      questList={questList}
      title="러닝 모임 미션"
      description="각 미션을 달성하고 리워드를 신청해보세요"
      localStorageKey="closedRunningGrowthBanner"
      groupId={groupId}
    />
  );
};

export default RunningGrowthMissionBanner;
