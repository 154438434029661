import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import {
  Divider,
  isSuperHost,
  Spacing,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useMemo } from 'react';

import { useGetApplications } from '@/api/hooks/useGetApplications';
import { useGetGroupFeedReviewWriteableMeetup } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';
import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import RunningGrowthMissionBanner from '@/components/group/Detail/components/RunningGrowth/RunningGrowthMissionBanner';

import GroupDetailMeetupReviewBanner from './GroupDetailMeetupReviewBanner';
import GroupDetailOnboardingBanner from './GroupDetailOnboardingBanner';
import GroupDetailWaitingMemberBanner from './GroupDetailWaitingMemberBanner';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupDetailMainBanner = ({ group, currentUser }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const { data: applications } = useGetApplications(groupId);
  const { data: reviewWriteable } = useGetGroupFeedReviewWriteableMeetup(groupId);
  const { runningGrowthData } = useGetRunningGrowthMission(groupId);

  const isShowApprovalBanner = useMemo(() => {
    if (!currentUser.permissions.applyApplicationMember) return false;
    if (group.management?.applicationCount === undefined) return false;
    if (group.management?.applicationCount <= 0) return false;

    return true;
  }, [group, currentUser]);

  const showRunningGrowthBanner = runningGrowthData && runningGrowthData.length > 0;

  // * 러닝 그로스 모임인 경우 +
  if (isSuperHost(currentUser.role) && showRunningGrowthBanner) {
    if (isShowApprovalBanner) {
      //가입 승인 대기 중인 멤버가 있다면
      // NOTE: isShowApprovalBanner 조건에 의해 검사된 로직이므로 항상 applications가 존재
      const memberCount = applications?.pages[0].data.applicationCount ?? 0;

      return (
        <>
          <GroupDetailWaitingMemberBanner
            group={group}
            currentUser={currentUser}
            memberCount={memberCount}
          />
          <Spacing height={16} />
          <Divider padding={0} />
          <RunningGrowthMissionBanner groupId={groupId} />
        </>
      );
    }

    // 작성해야 할 후기가 있는 경우
    if (reviewWriteable?.meetupId && showRunningGrowthBanner) {
      const meetupId = reviewWriteable?.meetupId.toString();
      return (
        <>
          <GroupDetailMeetupReviewBanner
            group={group}
            currentUser={currentUser}
            meetupId={meetupId}
          />
          <Spacing height={16} />
          <Divider padding={0} />
          <RunningGrowthMissionBanner groupId={groupId} />
        </>
      );
    }
  }

  // 가입 승인 대기중인 멤버가 있는 경우
  if (isShowApprovalBanner) {
    // NOTE: isShowApprovalBanner 조건에 의해 검사된 로직이므로 항상 applications가 존재
    const memberCount = applications?.pages[0].data.applicationCount ?? 0;

    return (
      <GroupDetailWaitingMemberBanner
        group={group}
        currentUser={currentUser}
        memberCount={memberCount}
      />
    );
  }

  // 작성해야 할 후기가 있는 경우
  if (reviewWriteable?.meetupId) {
    const meetupId = reviewWriteable?.meetupId.toString();

    return (
      <GroupDetailMeetupReviewBanner group={group} currentUser={currentUser} meetupId={meetupId} />
    );
  }

  return <GroupDetailOnboardingBanner group={group} currentUser={currentUser} />;
};

export default withAsyncBoundary(GroupDetailMainBanner, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
