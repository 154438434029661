import { postUploadImageV2 } from '@community-group/api';
import { PostCreateForm } from '@community-group/api/lib/group/models';
import {
  FileUploadState,
  ImageFieldItem,
  MentionInputField,
  useHookFormImages,
  useKeyboardSize,
  VideoFieldItem,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { PoiBanner } from '@/components/common/Banner/PoiBanner';
import FormGuideCallout from '@/components/common/FormGuideCallout';
import { Spacing } from '@/components/common/Spacing';
import { StackflowTheme } from '@/components/common/TransparentNavScreen/Navbar';
import { useBridge } from '@/contexts/Bridge';
import { usePlaceSearchClient } from '@/hooks/usePlaceSearchClient';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useTheme } from '@/stackflow/hooks/useTheme';
import { trackEvent } from '@/utils/analytics';

import { useMembersForMentions } from '../../../../hooks/useMembersForMentions';
import useHandleUploadVideo from '../hooks/useHandleUploadVideo';
import { PoiStream, useSubscribedPoiStream } from '../hooks/useSubscribedPoiStream';
import * as s from './GroupPostForm.css';
import { GroupPostFormPoll } from './GroupPostFormPoll';
import { GroupPostFormToolBar } from './ToolBar';

export type GroupPostFormHandlerProps = PostCreateForm & {
  poiStreams?: PoiStream[];
  prevImages: { id: string; src: string }[];
  isJoinedPhotoContest?: boolean;
  uploadVideos: FileUploadState[];
};

export interface GroupPostFormProps {
  formHandler: UseFormReturn<GroupPostFormHandlerProps>;
  mode?: 'new' | 'edit';
  handleAddButton?: () => void;
  inputConfig?: {
    autoFocus?: boolean;
  };
  mentionSuggestionsRef?: React.RefObject<HTMLDivElement>;
  textareaParentRef?: React.RefObject<HTMLDivElement>;
  isJoinedPhotoContest?: boolean; // Edit 모드에서만 사용
}

export const GroupPostForm = ({
  formHandler,
  inputConfig,
  mode,
  textareaParentRef,
  handleAddButton,
  mentionSuggestionsRef,
  isJoinedPhotoContest,
}: GroupPostFormProps) => {
  const fetchCommunityInstance = useFetchInstance();

  const { groupId = '' } = usePathParams();
  const { calloutType } = useQueryParams();
  const { openSearchPoiPage } = usePlaceSearchClient();

  const { watch, setValue, register } = formHandler;
  const { ref: contentRef } = register('content');

  const { cancelVideoUpload, retryVideoUpload } = useHandleUploadVideo({
    formHandler,
  });
  const { getTheme } = useTheme();
  const deviceType = useMemo(() => getTheme(), []) as StackflowTheme;
  const { bridge } = useBridge();
  const { images, removeImages, retryImage } = useHookFormImages({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleHookFormContext: () => formHandler,
    plugins: {
      bridge,
      postUploadImageV2: (imageFile) => postUploadImageV2({ imageFile, fetchCommunityInstance }),
    },
  });

  const mentionMemberList = useMembersForMentions(groupId);

  const { isKeyboardOn } = useKeyboardSize();
  const [isAndroidFocused, setIsAndroidFocused] = useState(false);

  const isFocused = isAndroidFocused || isKeyboardOn;
  const mentionStyle = useMemo(() => {
    return getMentionDefaultStyle(isKeyboardOn);
  }, [isKeyboardOn]);

  // useNavigation push 시 맨션 Input으로 인해 페이지 이동 애니메이션이 깨지는 이슈가 있음
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDidMount(true);
    }, 100);
  }, []);

  useSubscribedPoiStream({
    callback: (poi) => {
      setValue('poiStreams', [poi]);
    },
  });

  return (
    <div className={s.Wrapper} role="button">
      <Spacing height={8} />
      <GroupPostFormToolBar
        formHandler={formHandler}
        mode={mode}
        handleAddButton={handleAddButton}
        isJoinedPhotoContest={isJoinedPhotoContest}
      />
      {didMount && (
        <>
          <div className={s.ContentWrapper} ref={textareaParentRef}>
            <MentionInputField
              ref={contentRef}
              style={mentionStyle as CSSProperties}
              suggestionsPortalHost={mentionSuggestionsRef?.current ?? null}
              autoFocus={inputConfig?.autoFocus}
              value={watch('content')}
              onFocus={() => {
                if (deviceType === 'android') {
                  setIsAndroidFocused(true);
                }
              }}
              onBlur={() => {
                if (deviceType === 'android') {
                  setIsAndroidFocused(false);
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 4000) return;
                setValue('content', value);
              }}
              onKeyDown={(e) => {
                if (e.key !== 'Backspace' || watch('content').length !== 0) return;
                e.preventDefault();
              }}
              placeholder="질문이나 이야기를 남겨보세요"
              mentionConfig={[
                {
                  trigger: '@',
                  name: 'mentionedUserIds',
                  mentionList: mentionMemberList,
                  setMentionList: (currentMentionList: string[]) => {
                    setValue(
                      'mentionedUserIds',
                      currentMentionList.map((id) => parseInt(id))
                    );
                  },
                },
              ]}
            />
          </div>
          {watch('poll')?.options && <GroupPostFormPoll formHandler={formHandler} />}
          <div className={s.ImageWrapper}>
            {watch('uploadVideos')?.map((video) => {
              return (
                <VideoFieldItem
                  key={video.id}
                  video={video}
                  onRemoveClick={(file) => {
                    trackEvent({
                      event: 'click_remove_video',
                      params: {
                        groupId,
                        videoName: file.id,
                        status: file.status,
                        progress: file.progress,
                      },
                    });
                    cancelVideoUpload(file);
                  }}
                  onRetryClick={retryVideoUpload}
                />
              );
            })}
            {watch('prevImages').map(({ id, src }) => (
              <ImageFieldItem
                key={id}
                imageId={id}
                image={src}
                onRemoveClick={() => {
                  const filteredImages = watch('prevImages').filter((image) => image.id !== id);
                  setValue('prevImages', filteredImages);
                }}
              />
            ))}
            {images.map(({ id, image }) => (
              <ImageFieldItem
                key={id}
                imageId={id}
                image={image}
                onRemoveClick={() => {
                  removeImages(id);
                }}
                onRetryClick={() => {
                  retryImage(id);
                }}
              />
            ))}
          </div>
          {watch('poiStreams')?.[0] && (
            <div className={s.PoiWrapper}>
              <PoiBanner
                poi={{
                  id: watch('poiStreams')?.[0]?.poiId ?? 0,
                  type: watch('poiStreams')?.[0]?.type ?? 'POI',
                  name: watch('poiStreams')?.[0]?.poiName ?? '',
                }}
                onClick={() => {
                  openSearchPoiPage({});
                }}
                renderRight={() => (
                  <button
                    className={s.PoiBannerCloseButton}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      setValue('poiStreams', []);
                    }}
                  >
                    <IconCloseRegular size={20} color={vars.$scale.color.gray600} />
                  </button>
                )}
              />
            </div>
          )}
          {isFocused && calloutType && (
            <div className={s.FormGuideCalloutWrapper}>
              <FormGuideCallout calloutType={calloutType} />
            </div>
          )}
        </>
      )}
      <Spacing height={96} />
    </div>
  );
};

const defaultStyle = {
  control: {
    backgroundColor: `${vars.$semantic.color.paperDefault}`,
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  highlighter: {
    lineHeight: '1.5rem',
  },
  '&multiLine': {
    control: {},
    highlighter: {
      lineHeight: '1.5rem',
    },
    input: {
      minHeight: '7.5rem',
      outline: 0,
      border: 0,
      color: `${vars.$scale.color.gray900}`,
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      padding: 0,
    },
  },
  suggestions: {
    maxHeight: '168px',
    position: 'absolute',
    width: '100%',
    margin: 0,
    top: 'undefined',
    bottom: '49px',
    backgroundColor: `${vars.$semantic.color.paperDefault}`,
    zIndex: 999,

    list: {
      backgroundColor: `${vars.$semantic.color.paperDefault}`,
      borderTop: `1px solid ${vars.$semantic.color.divider1}`,
      fontSize: '1rem',
      width: '100%',
      fontWeight: 400,
      maxHeight: '168px',
      overflow: 'auto',
      lineHeight: '1.375rem',
    },
    item: {
      padding: '0.75rem 1rem',
      backgroundColor: `${vars.$semantic.color.paperDefault}`,
      '&first-child': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      '&focused': {
        backgroundColor: `${vars.$semantic.color.grayPressed}`,
      },
    },
  },
};

const getMentionDefaultStyle = (focused: boolean) => ({
  ...defaultStyle,
  suggestions: {
    ...defaultStyle.suggestions,
    bottom: focused ? '49px' : 'calc(env(safe-area-inset-bottom) + 49px)',
  },
  '&multiLine': {
    ...defaultStyle['&multiLine'],
    input: {
      ...defaultStyle['&multiLine'].input,
    },
  },
});
