import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconExpandLessRegular } from '@seed-design/icon';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useStorage } from '@/hooks/useStorage';
import { StorageData } from '@/hooks/useStorage/type';
import { TypeActivityNames } from '@/stackflow';

import { ProgressBar } from '../../ProgressBar';
import * as s from '../Onboarding.css';
import RunningGrowthOnboardingItem from './RunningGrowthOnboardingItem';

type Props<T> = {
  questList: T[];
  title: string;
  description: string;
  localStorageKey: keyof StorageData;
  groupId: string;
};

export type QuestionListType = {
  title: React.ReactNode | string;
  description: string;
  onButtonClick: () => void;
  isCompleted: boolean;
  activityName?: TypeActivityNames;
};

const RunningGrowthOnboardingBanner = ({
  questList,
  title,
  description,
  localStorageKey,
  groupId,
}: Props<QuestionListType>) => {
  const [closedOnboardingBanner, setClosedOnboardingBanner] = useStorage(localStorageKey, false);
  const completedQuestCount = questList.filter(({ isCompleted }) => isCompleted).length;
  const totalQuestCount = questList.length;

  const animateConfig = closedOnboardingBanner
    ? { opacity: 0, height: 0, transitionEnd: { display: 'none' } }
    : {
        opacity: 1,
        height: 'auto',
        transitionEnd: {
          display: 'block',
        },
      };

  // 슬라이드 페이지를 나누기 위해 3개씩 묶어줌
  const questChunk = questList.reduce((acc, curr, index) => {
    const chunkIndex = Math.floor(index / 3);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(curr);
    return acc;
  }, [] as QuestionListType[][]);

  const initialSlide = useMemo(() => {
    //questChunk 별로 모두 isSuccess가 아닌 chunk의 index
    const initialSlideIndex = questChunk.findIndex((chunk) =>
      chunk.some((quest) => !quest.isCompleted)
    );
    return Math.max(initialSlideIndex, 0);
  }, [questChunk]);

  return (
    <motion.div className={s.Container}>
      <div className={s.Header}>
        <div>
          <Typography typography="title3Bold">{title}</Typography>
          <Spacing height={4} />
          <Typography typography="subtitle2Regular">{description}</Typography>
        </div>
        <div>
          <button
            style={{
              transform: !closedOnboardingBanner ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 200ms ease-in-out',
            }}
            onClick={() => {
              setClosedOnboardingBanner(!closedOnboardingBanner);
            }}
          >
            <IconExpandLessRegular size={24} />
          </button>
        </div>
      </div>
      <Spacing height={8} />
      <div className={s.ProgressBarWrapper}>
        <ProgressBar
          value={completedQuestCount}
          max={totalQuestCount}
          width="100%"
          height=".25rem"
          borderRadius="0.5rem"
          fillColor={vars.$semantic.color.success}
          barColor={vars.$scale.color.gray300}
          transitionTimingFunction="ease-in"
          animateOnRender
          transitionDuration="0.3s"
        />
        <p className={s.ProgressBarLabel}>
          <span>{completedQuestCount}</span>/{totalQuestCount}
        </p>
      </div>
      <motion.div
        style={{ width: '100%' }}
        animate={animateConfig}
        initial={false}
        transition={{
          opacity: {
            duration: !closedOnboardingBanner ? 0.5 : 0.2,
            type: 'spring',
            bounce: 0.26,
          },
          height: {
            duration: 0.5,
            type: 'spring',
            bounce: 0.26,
          },
        }}
      >
        <Swiper
          modules={[Pagination]}
          pagination={true}
          initialSlide={initialSlide}
          autoHeight={true}
          style={
            {
              '--swiper-pagination-color': vars.$semantic.color.primary,
              '--swiper-pagination-bullet-size': '6px',
              '--swiper-pagination-bullet-horizontal-gap': '4px',
              '--swiper-pagination-bullet-inactive-color': vars.$scale.color.grayAlpha500,
              '--swiper-pagination-bottom': '0px',
            } as { [key: string]: string | number }
          }
        >
          {questChunk.map((chunkedQuestItem, index) => {
            return (
              <SwiperSlide key={index}>
                <ul className={s.QuestListWrapper}>
                  {chunkedQuestItem.map((item) => (
                    <RunningGrowthOnboardingItem
                      key={item.title?.toString()}
                      questItem={item}
                      localStorageKey={localStorageKey}
                      groupId={groupId}
                    />
                  ))}
                </ul>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </motion.div>
    </motion.div>
  );
};

export default RunningGrowthOnboardingBanner;
