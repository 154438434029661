import { HorizontalSpacer, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import React from 'react';

import icon_checked_control_circle from '@/assets/images/icon_checked_control_circle.png';
import icon_control_circle from '@/assets/images/icon_control_circle.png';
import { StorageData } from '@/hooks/useStorage/type';
import { trackEvent } from '@/utils/analytics';

import * as s from '../Onboarding.css';
import { QuestionListType } from './RunningGrowthOnboardingBanner';

type Props = {
  questItem: QuestionListType;
  localStorageKey: keyof StorageData;
  groupId: string;
};

const RunningGrowthOnboardingItem = ({ questItem, localStorageKey, groupId }: Props) => {
  const {
    title,
    description,
    onButtonClick: handleButtonClick,
    isCompleted,
    activityName,
  } = questItem;
  return (
    <li
      className={s.QuestItem}
      key={title?.toString()}
      onClick={() => {
        if (localStorageKey === 'closedRunningGrowthBanner') {
          handleButtonClick();
          return;
        }
        if (!isCompleted) {
          trackEvent({
            event: 'click_onboarding_mission_button',
            params: {
              groupId,
              activityName,
            },
            sample: true,
          });
          handleButtonClick();
        }
      }}
    >
      <img
        className={s.QuestItemIcon}
        src={isCompleted ? icon_checked_control_circle : icon_control_circle}
        alt={`${title} 아이콘`}
      />
      <div className={s.QuestInfoWrapper}>
        <Typography
          className={s.QuestItemTitle}
          as="h4"
          typography="bodyM2Regular"
          color="gray900"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
        </Typography>
        <HorizontalSpacer size={2} />
        <Typography
          className={s.QuestItemDescription}
          as="p"
          typography="caption1Regular"
          color="gray600"
        >
          {description}
        </Typography>
      </div>
      <IconChevronRightFill size={20} color={vars.$scale.color.gray500} />
    </li>
  );
};

export default RunningGrowthOnboardingItem;
